import { createContext, useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { makeURLWithQuery } from '@/lib/api';
import { SubscriptionStatus } from '@/lib/enums/billing';

import { BillingContext } from './BillingContext';

type IPaywallContext = ReturnType<typeof PaywallProvider>['value'];

export const PaywallContext = createContext<IPaywallContext>({} as IPaywallContext);

export default function PaywallProvider({ children }: { children: React.ReactNode }) {
  const [isPaywallDialogOpen, setIsPaywallDialogOpen] = useState<boolean>(false);
  const { billingInfo } = useContext(BillingContext);
  const location = useLocation();

  useEffect(() => {
    if (
      billingInfo?.subscription_status &&
      [SubscriptionStatus.PAST_DUE, SubscriptionStatus.UNPAID].includes(billingInfo.subscription_status) &&
      !location.pathname.startsWith('/login')
    ) {
      window.open(
        makeURLWithQuery(`${import.meta.env.VITE_PLATFORM_API_URL}/billing/create-customer-portal-session`, {
          callback: encodeURIComponent(location.pathname),
        }),
        '_blank'
      );
    }
  }, [billingInfo?.subscription_status]);

  const value = {
    isPaywallDialogOpen,
    setIsPaywallDialogOpen,
  } as const;

  return {
    ...(<PaywallContext.Provider value={value}>{children}</PaywallContext.Provider>),
    value,
  };
}
