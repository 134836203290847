import { ChannelTypeEnum, PaiTypeEnum } from '.';

export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  PAST_DUE = 'past_due',
  TRIALING = 'trialing',
  UNPAID = 'unpaid',
}

export const SubscriptionStatusMapping = {
  [SubscriptionStatus.ACTIVE]: 'Active',
  [SubscriptionStatus.CANCELED]: 'Canceled',
  [SubscriptionStatus.INCOMPLETE]: 'Incomplete',
  [SubscriptionStatus.INCOMPLETE_EXPIRED]: 'Incomplete (expired)',
  [SubscriptionStatus.PAST_DUE]: 'Past due',
  [SubscriptionStatus.TRIALING]: 'Trialing',
  [SubscriptionStatus.UNPAID]: 'Unpaid',
};

export enum PricingPlanName {
  FREE = 'free',
  LITE = 'lite',
  PRO = 'pro',
  TOTAL = 'total',
  ENTERPRISE = 'enterprise',
}

export const PricingPlanNameMapping = {
  [PricingPlanName.FREE]: 'Free',
  [PricingPlanName.LITE]: 'Lite',
  [PricingPlanName.PRO]: 'Pro',
  [PricingPlanName.TOTAL]: 'Total',
  [PricingPlanName.ENTERPRISE]: 'Enterprise',
};

export enum LengthUnit {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
  HOUR = 'hour',
}

export type DataSourceAutoSyncingFrequency = {
  length: number;
  length_unit: LengthUnit;
};

export type PricingPlan = {
  name: PricingPlanName;
  interactions_max_amount: number;

  // Chatbots
  chatbots_max_amount: number | null;
  chatbots_max_data_source_amount: number | null;
  chatbots_data_source_auto_syncing_frequency: DataSourceAutoSyncingFrequency[];
  chatbots_custom_behavior_and_tone: boolean;
  chatbots_language_max_amount: number | null;
  chatbots_available_channels: ChannelTypeEnum[];
  chatbots_available_pais: PaiTypeEnum[];

  // #TODO:
  // Inbox
  inbox_views_max_amount: number | null;
  inbox_can_transfer_conversation: boolean;
  // #Livechat
  // #Tickets
  // #Customers
  // #Apps
  // #Integrations

  // Platform Management
  platform_management_user_max_amount: number | null;
  platform_management_team_max_amount: number | null;
  platform_management_subcompany_max_amount: number | null;

  // Data Privacy
  // Analytics
  // Contact Centre
  // Enterprise Max
};

export enum StripeBillingPortalFlowType {
  PAYMENT_METHOD_UPDATE = 'payment_method_update',
}
