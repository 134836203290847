import { useContext, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { toast } from 'sonner';

import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Spinner } from '@/components/Spinner';
import { useMutation } from '@/lib/api';
import { PermissionDenied } from '@/lib/exceptions/platform';
import { cn } from '@/lib/utils';
import { PaywallContext } from '@/context/PaywallContext';

dayjs.extend(utc);
dayjs.extend(timezone);

export function PaywallDialog() {
  const [customerSessionExpireTimeout, setCustomerSessionExpireTimeout] = useState<NodeJS.Timeout | null>(null);
  const { isPaywallDialogOpen, setIsPaywallDialogOpen } = useContext(PaywallContext);

  const {
    trigger: createCustomerSession,
    data: customerSession,
    error: createCustomerSessionError,
    isMutating: isCreatingCustomerSession,
  } = useMutation<
    undefined,
    {
      client_secret: string;
      created: string;
      expires_at: string;
      pricing_table_id: string;
    }
  >(isPaywallDialogOpen ? '/billing/prepare-subscription-checkout-session' : null, 'POST');

  useEffect(() => {
    if (isPaywallDialogOpen) {
      createCustomerSession()
        .then(({ expires_at }) => {
          const countDownMs = Math.max(dayjs.tz(expires_at, 'Etc/UTC').diff(dayjs(), 'millisecond'), 0);
          setCustomerSessionExpireTimeout(
            setTimeout(() => {
              setIsPaywallDialogOpen(false);
              toast.error('Checkout session expired, please try again.');
            }, countDownMs)
          );
        })
        .catch((error) => {
          setIsPaywallDialogOpen(false);
          if (error instanceof PermissionDenied) {
            toast.error('You do not have permission to perform this action.');
          } else {
            toast.error('An error occurred, please try again.');
          }
        });
    } else {
      if (customerSessionExpireTimeout) {
        clearTimeout(customerSessionExpireTimeout);
        setCustomerSessionExpireTimeout(null);
      }
    }
  }, [isPaywallDialogOpen]);

  useEffect(() => {
    return () => {
      if (customerSessionExpireTimeout) {
        clearTimeout(customerSessionExpireTimeout);
        setCustomerSessionExpireTimeout(null);
      }
    };
  }, []);

  return (
    <Dialog open={isPaywallDialogOpen} onOpenChange={setIsPaywallDialogOpen}>
      <DialogContent
        className={cn(
          'max-h-[95dvh] overflow-auto p-8',
          !createCustomerSessionError && !isCreatingCustomerSession && 'min-w-[90vw]'
        )}
      >
        {isCreatingCustomerSession ? (
          <div className="flex items-center space-x-2 text-center">
            <Spinner />
            <div>Loading...</div>
          </div>
        ) : (
          !createCustomerSessionError &&
          customerSession && (
            <stripe-pricing-table
              pricing-table-id={customerSession.pricing_table_id}
              customer-session-client-secret={customerSession.client_secret}
              publishable-key={import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY}
            ></stripe-pricing-table>
          )
        )}
      </DialogContent>
    </Dialog>
  );
}
