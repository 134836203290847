import { fetcher, useFetch } from '@/lib/api';
import { PricingPlanName, SubscriptionStatus } from '@/lib/enums/billing';
import { SWR_KEYS } from '@/lib/enums/swr';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext } from './AuthContext';

type IBilling = ReturnType<typeof BillingProvider>['value'];

type BillingInfo = {
  period_start: string;
  period_end: string;
  interaction_count: number;
  interaction_quota: number;
  subscription_status: SubscriptionStatus;
  billing_period_interval: string;
  billing_period_interval_count: number;
  upcoming_invoice: {
    currency: string;
    total: number;
  } | null;
  next_billing_date: string;
};

export const BillingContext = createContext<IBilling>({} as IBilling);

export default function BillingProvider({ children }: { children: React.ReactNode }) {
  const { id } = useContext(AuthContext);
  const { data: activeProducts } = useFetch<PricingPlanName[]>(
    SWR_KEYS.GET_ACTIVE_PRODUCTS,
    id ? '/billing/get-active-products-v2' : null
  );

  const [billingInfo, setBillingInfo] = useState<BillingInfo | null>(null);

  const activeAddonsMap = useMemo<Record<PricingPlanName, boolean> | null>(() => {
    if (activeProducts) {
      const _activeAddonsMap = {} as Record<PricingPlanName, boolean>;
      for (const product of Object.values(PricingPlanName)) {
        _activeAddonsMap[product] = activeProducts.includes(product);
      }
      return _activeAddonsMap;
    } else {
      return null;
    }
  }, [activeProducts]);

  const getBillingInfo = async () => {
    const data = await fetcher<BillingInfo>('/billing/get-billing-info', 'GET');
    setBillingInfo(data);
  };

  const canAccountUseMessage = useMemo(() => {
    return billingInfo ? billingInfo.interaction_count < billingInfo.interaction_quota : false;
  }, [billingInfo]);

  const interactionsUsagePercentage = useMemo(() => {
    if (!billingInfo?.interaction_count) return 0;
    // Return a minimum of 1% to avoid showing 0% when the usage is over 0%.
    const MIN_PERCENTAGE = 1;
    return Math.max(
      MIN_PERCENTAGE,
      parseFloat((billingInfo.interaction_count / billingInfo.interaction_quota).toFixed(2)) * 100
    );
  }, [billingInfo]);

  const updateInteractionCount = (count: number) => {
    if (billingInfo) {
      setBillingInfo({ ...billingInfo, interaction_count: count });
    }
  };

  useEffect(() => {
    getBillingInfo();
  }, []);

  const value = {
    activeAddonsMap,
    billingInfo,
    interactionsUsagePercentage,
    updateInteractionCount,
    canAccountUseMessage,
  } as const;

  return {
    ...(<BillingContext.Provider value={value}>{children}</BillingContext.Provider>),
    value,
  };
}
