import { useContext, useMemo } from 'react';

import { ProductFruits } from 'react-product-fruits';

import { AuthContext } from '@/context/AuthContext';

interface UserInfo {
  // https://help.productfruits.com/en/article/identifying-users
  username: string; // Use email for this field, as advised by Product Fruits
  firstname?: string;
  lastname?: string;
  signUpAt?: string; // Must be ISO 8601 datetime format with UTC timezone (yyyy-mm-ddThh:mm:ssZ)
  role?: string; // If you need multiple roles, use a custom property
  props?: { [key: string]: string | number | boolean | string[] | number[] };
}

export function ProtoProductFruits() {
  const { email, firstName, lastName } = useContext(AuthContext);

  const workspaceCode = import.meta.env.VITE_PRODUCT_FRUITS_WORKSPACE_CODE || '';

  const userInfo: UserInfo = useMemo(
    () => ({
      username: email || '',
      firstname: firstName,
      lastname: lastName,
    }),
    [email, firstName, lastName]
  );

  // Check necessities
  if (!workspaceCode?.trim() || !email?.trim()) {
    return null;
  }

  return (
    <ProductFruits
      workspaceCode={workspaceCode}
      language="en"
      user={userInfo}
      // lifeCycle="unmount"
    />
  );
}
